import React from 'react';
import '../styles/templates/news.css';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Container from 'react-bootstrap/Container';

import Layout from '../components/layout';

const News = (props) => {
  const {
    data: {
      markdownRemark: {
        frontmatter: { title, date, featured },
        html,
      },
    },
  } = props;
  return (
    <Layout>
      <div className="NewsPost">
        <Container>
          <h3 className="NewsPost--title">{title}</h3>
          <time className="NewsPost--date">{date}</time>
          {featured && (
            <div className="NewsPost__img" style={{ height: '250px', overflow: 'hidden' }}>
              <Img fluid={featured.childImageSharp.fluid} />
            </div>
          )}

          {/* eslint-disable */}
          <div style={{ color: '#666' }} dangerouslySetInnerHTML={{ __html: html }} />
          {/* eslint-disable */}
        </Container>
      </div>
    </Layout>
  );
};

export const newsQuery = graphql`
  query NewsQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      excerpt
      html
      frontmatter {
        date(formatString: "DD MMMM, YYYY")
        title
        featured {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;

export default News;
